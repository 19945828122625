import classNames from 'classnames';
import { baseStyles, getThemeClasses } from '../../ui.style.tailwind';

// Set the mode (can be dynamic)
const mode = 'dark';
const themeClasses = getThemeClasses(mode);

// lib/components/lists/amenities/dashboard/src/lib/components/close/amenity-link-close.tsx
export const anchorClassesCombined = classNames(
  themeClasses.additionalStyles?.textColor, // Use the text color from themeClasses
  'rounded-full', // Use rounded style
  'px-2.5',
  'py-1',
  'text-xs',
  'font-semibold',
  'shadow-sm',
  themeClasses.hoverBackgroundColor, // Use hover background color
  themeClasses.hoverTextColor, // Use hover text color
);

// lib/components/lists/amenities/dashboard/src/lib/components/close///*amenity-item-close.tsx
export const itemClassesClose = classNames(
  themeClasses.oppositeTextColor, // Use text color from themeClasses
  'flex',
  'items-center',
  'justify-between',
  'gap-x-6',
  'p-2',
  'my-2',
  // themeClasses.backgroundColor, // Use background color from themeClasses
  baseStyles.borderRadius, // Use border radius from themeClasses or default to rounded-xl
);

// lib/components/lists/amenities/dashboard/src/lib/components/close///*amenity-item-open.tsx
export const itemActiveClasses = classNames(
  'w-full',
  'flex',
  'items-center',
  'justify-between',
  'gap-x-6',
  'p-2',
  'my-2',
  themeClasses.backgroundColor, // Use background color from themeClasses
  baseStyles.borderRadius, // Use border radius from themeClasses or default to rounded-xl
);

// lib/components/lists/amenities/dashboard/src/lib/components/close/amenity-info-close.tsx
export const minWFlexAutoClasses = 'min-w-0 flex-auto';
export const textBaseClasses = classNames(
  'text-sm',
  baseStyles.fontWeight.emphasis, // Use base font weight from baseStyles
  'leading-6',
  // themeClasses.textColor, // Use text color from themeClasses
);
export const textGray900 =
  themeClasses.additionalStyles?.textColor || 'text-gray-900';
export const textGray500 = classNames(
  'mt-1',
  'truncate',
  'text-xs',
  'leading-5',
  themeClasses.additionalStyles?.textColor || 'text-gray-500',
);

// lib/components/lists/amenities/dashboard/src/lib/components/open/amenity-info-open.tsx
export const descriptionClasses = classNames(
  textGray500,
  themeClasses.textColor || 'text-white', // Use white text color as fallback
);

export const containerClasses = classNames(
  minWFlexAutoClasses,
  themeClasses.textColor || 'text-white', // Use white text color as fallback
);

export const nameClasses = themeClasses.textColor;

// lib/components/lists/amenities/dashboard/src/lib/components/open///*amenity-link-open.tsx
export const anchorClassesCombinedOpen = classNames(
  'rounded-full', // Use rounded style
  'px-2.5',
  'py-1',
  'text-xs',
  'font-semibold',
  'shadow-sm',
  'ring-1',
  'ring-inset',
  themeClasses.oppositeBackgroundColor, // Use active background color or default to bg-secondary_dark
  themeClasses.oppositeTextColor, // Use text color or default to text-white
  themeClasses.focusRingColor, // Use focus ring color or default to ring-gray-300
  themeClasses.hoverBackgroundColor, // Use hover background color or default to hover:bg-gray-300
  themeClasses.hoverTextColor, // Use hover text color or default to hover:text-blue-700
);
