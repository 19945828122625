// Define types for the color modes and styles

//! primary_dark: '#132F51',
//! payment_background: '#214882',
//! primary_light: '#1AB0FF',
//! primary_light_2: '#0D7FC0',
//! secondary_dark: '#1D62CB',
//! secondary_light: '#19A9F8',
//! third_color: '#0D7FC0',

type ColorMode = 'light' | 'dark';

interface HoverStyles {
  backgroundColor: string;
  textColor: string;
  oppositeBackgroundColor: string;
  oppositeTextColor: string;
}

interface ActiveStyles {
  backgroundColor: string;
  textColor: string;
}

interface FocusStyles {
  ringColor: string;
}

interface AdditionalStyles {
  textColor?: string;
  borderColor?: string;
}

interface ThemeColors {
  color: string;
  backgroundColor: string;
  textColor: string;
  oppositeBackgroundColor: string; // Opposite background color for non-hover state
  oppositeTextColor: string; // Opposite text color for non-hover state
  fillSVG: string; // New property for SVG fill color
  hover: HoverStyles;
  active: ActiveStyles;
  focus: FocusStyles;
  additionalStyles?: AdditionalStyles;
}

// Define the color schemes for light and dark modes
export const tailwindColors: Record<ColorMode, ThemeColors> = {
  light: {
    color: 'bg-white',
    backgroundColor: 'bg-white',
    textColor: 'text-black',
    oppositeBackgroundColor: 'bg-black', // Opposite background color for non-hover state
    oppositeTextColor: 'text-white', // Opposite text color for non-hover state
    fillSVG: 'fill-primary_light', // Fill color for light mode
    hover: {
      backgroundColor: 'hover:bg-gray-100',
      textColor: 'hover:text-gray-700',
      oppositeBackgroundColor: 'hover:bg-black',
      oppositeTextColor: 'hover:text-white',
    },
    active: {
      backgroundColor: 'active:bg-gray-200',
      textColor: 'active:text-black',
    },
    focus: {
      ringColor: 'focus:ring-gray-500',
    },
  },
  dark: {
    color: 'secondary_dark',
    backgroundColor: 'bg-secondary_dark',
    textColor: 'text-white',
    oppositeBackgroundColor: 'bg-white',
    oppositeTextColor: 'text-secondary_dark',
    fillSVG: 'fill-secondary_dark', // Fill color for dark mode
    hover: {
      backgroundColor: 'hover:bg-secondary_dark',
      textColor: 'hover:text-white',
      oppositeBackgroundColor: 'hover:bg-white',
      oppositeTextColor: 'hover:text-secondary_dark',
    },
    active: {
      backgroundColor: 'active:bg-secondary_dark',
      textColor: 'active:text-white',
    },
    focus: {
      ringColor: 'focus:ring-secondary_dark',
    },
    additionalStyles: {
      textColor: 'text-secondary_dark',
      borderColor: 'border-secondary_dark',
    },
  },
};

/**
 * Utility function to get the theme classes based on the provided mode.
 *
 * @param mode - The color mode ('light' or 'dark'). Defaults to 'light'.
 * @returns An object containing the Tailwind CSS classes for background, text, hover, active, focus, and additional styles.
 */
export function getThemeClasses(mode: ColorMode = 'light') {
  const theme = tailwindColors[mode];

  return {
    color: theme.color,
    backgroundColor: theme.backgroundColor,
    textColor: theme.textColor,
    fillSVG: theme.fillSVG,
    oppositeBackgroundColor: theme.oppositeBackgroundColor, // Opposite background color for non-hover state
    oppositeTextColor: theme.oppositeTextColor, // Opposite text color for non-hover state
    hoverBackgroundColor: theme.hover.backgroundColor,
    hoverTextColor: theme.hover.textColor,
    hoverOppositeBackgroundColor: theme.hover.oppositeBackgroundColor,
    hoverOppositeTextColor: theme.hover.oppositeTextColor,
    activeBackgroundColor: theme.active.backgroundColor,
    activeTextColor: theme.active.textColor,
    focusRingColor: theme.focus.ringColor,
    additionalStyles: theme.additionalStyles || {},
    borderRadius: baseStyles.borderRadius, // Include base border radius
  };
}


/**
 * Base styles for common UI elements across all themes.
 *
 * @typedef {Object} BaseStyles
 * @property {string} borderRadius - The default border radius applied to elements, using Tailwind CSS classes. Value: `'rounded-xl'`.
 * @property {Object} borderRadiusOptions - Additional border radius options using Tailwind CSS classes.
 * @property {string} borderRadiusOptions.none - No border radius. Value: `'rounded-none'`.
 * @property {string} borderRadiusOptions.small - Small border radius. Value: `'rounded-sm'`.
 * @property {string} borderRadiusOptions.base - Base border radius. Value: `'rounded'`.
 * @property {string} borderRadiusOptions.medium - Medium border radius. Value: `'rounded-md'`.
 * @property {string} borderRadiusOptions.large - Large border radius. Value: `'rounded-lg'`.
 * @property {string} borderRadiusOptions.extraLarge - Extra large border radius. Value: `'rounded-xl'`.
 * @property {string} borderRadiusOptions.full - Full border radius for circular elements. Value: `'rounded-full'`.
 * @property {Object} textSize - Defines text sizes for different devices.
 * @property {string} textSize.small - Small text size. Value: `'text-sm'`.
 * @property {string} textSize.base - Default text size. Value: `'text-base'`.
 * @property {string} textSize.large - Large text size. Value: `'text-lg'`.
 * @property {Object} textSize.laptop - Text sizes specifically for laptop devices.
 * @property {string} textSize.laptop.base - Base text size for laptops. Value: `'laptop:text-base'`.
 * @property {string} textSize.laptop.h3 - Heading 3 text size for laptops. Value: `'laptop:text-lg'`.
 * @property {string} textSize.laptop.h2 - Heading 2 text size for laptops. Value: `'laptop:text-xl'`.
 * @property {string} textSize.laptop.h1 - Heading 1 text size for laptops. Value: `'laptop:text-2xl'`.
 * @property {Object} textSize.phone - Text sizes specifically for phone devices.
 * @property {string} textSize.phone.base - Base text size for phones. Value: `'phone:text-sm'`.
 * @property {string} textSize.phone.h3 - Heading 3 text size for phones. Value: `'phone:text-base'`.
 * @property {string} textSize.phone.h2 - Heading 2 text size for phones. Value: `'phone:text-lg'`.
 * @property {string} textSize.phone.h1 - Heading 1 text size for phones. Value: `'phone:text-xl'`.
 * @property {Object} fontWeight - Defines font weights for different text elements.
 * @property {string} fontWeight.base - Default font weight for general text. Value: `'font-normal'`.
 * @property {string} fontWeight.heading - Font weight for headings, typically bold. Value: `'font-bold'`.
 * @property {string} fontWeight.emphasis - Font weight for emphasized text, typically semi-bold. Value: `'font-semibold'`.
 */

/** @type {BaseStyles} */
export const baseStyles = {
  padding: {
    small: 'p-2',
    base: 'p-4',
    large: 'p-6',
  },
  color: {
    primary: '#132F51',
    payment_background: '#214882',
    primary_light: '#1AB0FF',
    primary_light_2: '#0D7FC0',
    secondary_dark: '#1D62CB',
    secondary_light: '#19A9F8',
    third_color: '#0D7FC0',
  },
  borderRadius: 'rounded-xl',
  borderRadiusOptions: {
    none: 'rounded-none',
    small: 'rounded-sm',
    base: 'rounded',
    medium: 'rounded-md',
    large: 'rounded-lg',
    extraLarge: 'rounded-xl',
    full: 'rounded-full',
  },
  textSize: {
    small: 'text-sm',
    base: 'text-base',
    large: 'text-lg',
    laptop: {
      base: 'laptop:text-base',
      h3: 'laptop:text-lg',
      h2: 'laptop:text-xl',
      h1: 'laptop:text-2xl',
    },
    phone: {
      base: 'phone:text-sm',
      h3: 'phone:text-base',
      h2: 'phone:text-lg',
      h1: 'phone:text-xl',
    },
  },
  fontWeight: {
    base: 'font-normal',
    emphasis: 'font-semibold',
    heading: 'font-bold',
  },
};
